import { css } from '@emotion/react';

export const title = css`
    color: #ffb300;
    font-weight: 900;
    font-size: 1.6rem;
    margin-bottom: 1rem;
`;

export const flexWrapper = css`
    max-width: 20rem;
    margin: auto;

    @media (min-width: 37.5rem) {
        max-width: none;
        margin: 0;
        display: flex;
        align-items: flex-start;
    }
`;

export const imageWrapper = css`
    margin-bottom: 2rem;

    @media (min-width: 37.5rem) {
        flex: 1 1 40%;
    }
`;

export const mainImage = css`
    border-radius: 0.5rem;
    overflow: hidden;
    display: block;
    position: relative;

    & :nth-of-type(2) {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
`;

export const thumbnails = css`
    display: flex;
    flex-wrap: wrap;
    padding-top: 1rem;

    & > * {
        flex: 0 0 calc(20% - 0.4rem);
        cursor: pointer;
        margin-right: 0.5rem;
        margin-bottom: 0.5rem;
        border-radius: 0.2rem;
        overflow: hidden;
        transform: scale(1) rotate(0);
        transition: transform 0.2s;

        &:hover,
        &:focus {
            transform: scale(1.1) rotate(2deg);
        }

        &:nth-of-type(5n) {
            margin-right: 0;
        }
    }
`;

export const infoWrapper = css`
    @media (min-width: 37.5rem) {
        flex: 1 1 50%;
        padding-left: 4rem;
    }
`;

export const breadcrumbs = css`
    font-weight: 500;
    color: #777;
    margin-bottom: 4rem;
    font-size: 0.9rem;

    a {
        color: #777;
        text-decoration: none;

        &:hover {
            text-decoration: underline;
        }
    }

    span {
        /* font-weight: 900; */
        color: #444;
    }
`;

export const description = css`
    line-height: 1.4;
    color: #555;
    font-size: 0.9rem;
    margin-bottom: 3rem;
`;

export const cost = css`
    color: #ffb300;
    font-size: 4rem;
    font-weight: 500;
    text-align: center;
    margin-left: 3rem;
`;

export const selects = css`
    & > * {
        margin-bottom: 0.5rem;

        &:last-of-type {
            margin-bottom: 2rem;
        }
    }
`;

export const inputWrapper = css`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 3rem;
`;

export const callout = css`
    margin-top: 2rem;
    margin-bottom: 0.5rem;
    text-align: right;
    font-size: 0.8rem;
    font-weight: 500;
`;
