import React, { useState } from 'react';
import { PageProps, Link, graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import { OutboundLink } from 'gatsby-plugin-google-analytics';
import { motion, AnimatePresence } from 'framer-motion';

import { ProductGroupBySlugPageQuery } from '@lib/types';
import { useCart } from '@hooks';
import { Wrapper, SEO, Counter, Price, Button, Select, Alert, AvailabilityButton } from '@components';

import * as s from './styles';

const ProductGroup: React.FC<PageProps<ProductGroupBySlugPageQuery>> = ({ data }) => {
    const { add } = useCart();
    const [quantity, setQuantity] = useState(1);
    const [{ key, message }, setButtonStatus] = useState({ key: 0, message: 'Add to cart' });
    const { slug, name: groupName, attributes, unifiedItems } = data.unifiedItemGroup;
    const {
        rootCloverItem: {
            imageFiles,
            markdownFile: {
                childMarkdownRemark: { html },
            },
        },
    } = unifiedItems.find((item) => item.rootCloverItem.imageFiles !== null) ?? unifiedItems[0];
    const [selectedItem, setSelectedItem] = useState(unifiedItems[0]);
    const {
        rootCloverItem: { code, name, price, taxRates, categories, options },
    } = selectedItem;
    const [{ gatsbyImageData, gatsbyImageDataLarge }, setGatsbyImage] = useState(imageFiles[0].childImageSharp);
    const allImageFiles = unifiedItems.flatMap((item) => item.rootCloverItem.imageFiles ?? []);
    const mainCategoryMarkdown = (
        categories.find(
            (category) => category.markdownFile && category.markdownFile.childMarkdownRemark.fields.slug !== ''
        ) ?? categories[0]
    ).markdownFile.childMarkdownRemark;
    const inStoreOnly = Boolean(categories.find((category) => category.name === 'Website No Sale'));

    const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const selectedAttribute = attributes.find((attribute) => attribute.name === event.target.name);
        const selectedAttributeIndex = selectedAttribute ? attributes.indexOf(selectedAttribute) : 0;
        const selectedAttributeValue = event.target.value;
        const nextOptions = options
            .map((option, index) => (index === selectedAttributeIndex ? selectedAttributeValue : option.name))
            .join(',');
        const nextItem = unifiedItems.find(
            (item) => item.rootCloverItem.options.map((option) => option.name).join(',') === nextOptions
        );

        if (nextItem) {
            setSelectedItem(nextItem);
            if (nextItem.rootCloverItem.imageFiles !== null) {
                setGatsbyImage(nextItem.rootCloverItem.imageFiles[0].childImageSharp);
            }
        } else {
            const fallbackItem =
                unifiedItems.find((item) =>
                    item.rootCloverItem.options.find((option) => option.name === selectedAttributeValue)
                ) ?? unifiedItems[0];

            setSelectedItem(fallbackItem);
            if (fallbackItem.rootCloverItem.imageFiles !== null) {
                setGatsbyImage(fallbackItem.rootCloverItem.imageFiles[0].childImageSharp);
            }
        }
    };

    const handleButtonClick = () => {
        add(
            {
                code,
                name,
                slug,
                price,
                taxRates,
                gatsbyImageData,
            },
            quantity
        );
        setButtonStatus({ key: 1, message: 'Added to your cart!' });
        setTimeout(() => {
            setButtonStatus({ key: 0, message: 'Add to cart' });
        }, 2000);
    };

    return (
        <Wrapper>
            <SEO title={`Product | ${groupName}`} description={groupName} />
            <div css={s.breadcrumbs}>
                <Link to="/">Home</Link> / <Link to="/products">Products</Link> /{' '}
                <Link to={`/products/${mainCategoryMarkdown.fields.slug}`}>
                    {mainCategoryMarkdown.frontmatter.title}
                </Link>{' '}
                / <span>{groupName}</span>
            </div>
            <div css={s.flexWrapper}>
                <div css={s.imageWrapper}>
                    <div css={s.mainImage}>
                        <AnimatePresence initial={false}>
                            <motion.div
                                key={gatsbyImageDataLarge.images.fallback?.src}
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                exit={{ opacity: 1 }}
                                transition={{ duration: 0.3 }}
                            >
                                <OutboundLink href={gatsbyImageDataLarge.images.fallback?.src}>
                                    <GatsbyImage image={gatsbyImageData} alt={name} />
                                </OutboundLink>
                            </motion.div>
                        </AnimatePresence>
                    </div>
                    {allImageFiles.length > 1 && (
                        <div css={s.thumbnails}>
                            {allImageFiles.map((imageFile) => (
                                <GatsbyImage
                                    key={imageFile.childImageSharp.gatsbyImageData.images.fallback?.src}
                                    image={imageFile.childImageSharp.gatsbyImageData}
                                    alt={name}
                                    onClick={() => setGatsbyImage(imageFile.childImageSharp)}
                                />
                            ))}
                        </div>
                    )}
                </div>
                <div css={s.infoWrapper}>
                    <h2 css={s.title}>{groupName}</h2>
                    <div css={s.description} dangerouslySetInnerHTML={{ __html: html }} />
                    {attributes && (
                        <div css={s.selects}>
                            {attributes.map((attribute, index) => (
                                <Select
                                    key={attribute.name}
                                    label={attribute.name}
                                    options={attribute.options.map((option) => ({
                                        name: option.name,
                                        value: option.name,
                                    }))}
                                    value={options[index].name}
                                    onChange={handleSelectChange}
                                />
                            ))}
                        </div>
                    )}
                    <div css={s.inputWrapper}>
                        <div style={{ visibility: inStoreOnly ? 'hidden' : 'visible' }}>
                            <Counter value={quantity} onChange={(value) => value > 0 && setQuantity(value)} />
                        </div>
                        <p css={s.cost}>
                            <Price value={price} />
                        </p>
                    </div>
                    {inStoreOnly ? (
                        <Alert>This product is only available in store</Alert>
                    ) : (
                        <Button id={key} color="#388e3c" onClick={handleButtonClick}>
                            <span style={{ textTransform: key === 0 ? 'uppercase' : undefined }}>{message}</span>
                        </Button>
                    )}
                    <p css={s.callout}>Want to check it out in-store?</p>
                    <AvailabilityButton code={code} />
                </div>
            </div>
        </Wrapper>
    );
};

export default ProductGroup;

export const pageQuery = graphql`
    query ProductGroupBySlug($slug: String!) {
        unifiedItemGroup(slug: { eq: $slug }) {
            slug
            name
            attributes {
                name
                options {
                    name
                }
            }
            unifiedItems {
                rootCloverItem {
                    code
                    name
                    price
                    taxRates {
                        elements {
                            rate
                        }
                    }
                    options {
                        name
                    }
                    categories {
                        name
                        markdownFile {
                            childMarkdownRemark {
                                frontmatter {
                                    title
                                }
                                fields {
                                    slug
                                }
                            }
                        }
                    }
                    markdownFile {
                        childMarkdownRemark {
                            html
                        }
                    }
                    imageFiles {
                        childImageSharp {
                            gatsbyImageData(
                                layout: CONSTRAINED
                                width: 500
                                height: 500
                                placeholder: BLURRED
                                transformOptions: { cropFocus: CENTER, fit: COVER }
                            )
                            gatsbyImageDataLarge: gatsbyImageData(
                                layout: CONSTRAINED
                                width: 800
                                height: 600
                                transformOptions: { cropFocus: CENTER, fit: OUTSIDE }
                            )
                        }
                    }
                }
            }
        }
    }
`;
